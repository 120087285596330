<!--
 * @Description: 
 * @Author: wqnan
 * @Date: 2021-10-11 14:27:24
-->
<template>
  <div class="home">
    <el-row :gutter="0">
      <!-- pc端 -->
      <el-col :span="24" v-if="shufflingList.length>0">
        <div class="carousel">
          <el-carousel :height="isPc ? '357px' : '47.6vw'" style="z-index: 10" indicator-position="none">
            <el-carousel-item v-for="(item, index) in shufflingList" :key="index">
              <div :style="`width: 100%; height: ${ isPc ? '357px' : '47.6vw' }`">
                <img style="width: 100%; height: 100%"
                  :src="item.adImg"/>
              </div>
            </el-carousel-item>
          </el-carousel>
          
          <!-- 公告 -->
          <div class="gonggao" style="z-index: 999">
            <Announcement></Announcement>
          </div>
        </div>
        <!-- <Information ref = "information"></Information> -->
        <div v-for="(item, index) in floorList" :key="index">
          <!-- 新闻资讯  -->
          <Information v-if="item.moduleCode == 1" :item="item"></Information>
          <!-- 培训课程 -->
          <OnlineCourses v-if="item.moduleCode == 3" :item="item"></OnlineCourses>
          <!-- 培训项目 -->
          <TrainingActivities v-if="item.moduleCode == 2" :item="item"></TrainingActivities>
          <!-- 直播课程 -->
          <LiveEvents v-if="item.moduleCode == 4" :item="item"></LiveEvents>
          <!-- 会议列表 v-if="item.moduleCode == 5" -->
          <Conference v-if="item.moduleCode == 5" :item="item"></Conference>
        </div>
      </el-col>
      <!-- h5端 -->
      <!-- <el-col :span="24" class="hidden-sm-and-up">
        1323
      </el-col> -->
    </el-row>
    <div class="footerlink">
      <div v-for="(item, index) in footerlinkImg" :key="index" class="imgBox">
        <img :src="item.adImg" alt="" @click="jumpUrl(item.adLink)">
      </div>
    </div>
  </div>
</template>
<script>
import OnlineCourses from "./components/home/OnlineCourses.vue";
import LiveEvents from "./components/home/LiveEvents.vue";
import TrainingActivities from "./components/home/TrainingActivities.vue";
import Information from "./components/home/Information.vue";
import Conference from "./components/home/conference.vue";
import Announcement from "./components/announcement/index.vue";
import $ from "jquery";
export default {
  components: {
    OnlineCourses,
    LiveEvents,
    TrainingActivities,
    Information,
    Conference,
    Announcement,
  },
  data() {
    return {
      token: localStorage.getItem("pc_token") ?? '',
      floorList: [
        // { title: "新闻资讯", more: "更多" },
        // { title: "在线课程", more: "全部" },
        // { title: "内部培训", more: "全部" },
        // { title: "直播活动", more: "全部" },
      ],
      shufflingList: [],
      onlineList: [],
      domainName: "",
      domainNameList: {},
      footerlinkImg: [],
      footerBgImg: ''
    };
  },
  mounted() {
    this.domainName = window.location.hostname;
    localStorage.setItem("domainName", window.location.hostname);
    this.getAppByDomainNameApi();

    this.getAppAdContentApi();
    this.getAppFloorApi();
    this.getAppAdContentApiF()

  },
  computed: {
    isPc() {
			return window.screen.width <= 767 ? false : true;
		},
  },
  methods: {
    //  域名接口
    getAppByDomainNameApi() {
      // this.$api.getAppByDomainNameApi({
      //   domainName:this.domainName
      //   // domainName:'sny.acoer.cn'
      // }).then(res => {
      //   if(res.code == 10000){
      //     this.domainNameList = res.data
      //     localStorage.setItem('channelId',res.data.channelId)
      //     window.VUE_APP_TITLE = res.data.channelName
      //   }
      // })
      localStorage.setItem("channelId", "NJGS");
    },
    // 轮播图接口
    getAppAdContentApi() {
      this.$api
        .selectContentList({
          channelId: this.$http.channelId,
          token: this.token,
          codes: "000001",
        })
        .then((res) => {
          if (res.code == 10000) {
            this.shufflingList = res.data;
          }
        });
    },
    getAppAdContentApiF() {
      this.$api
        .selectContentList({
          channelId: this.$http.channelId,
          token: this.token,
          codes: "000004",
        })
        .then((res) => {
          if (res.code == 10000) {
            this.footerlinkImg = res.data
          }
        });
    },

    // 模板楼层接口
    getAppFloorApi() {
      this.$api
        .getAppFloorApi({
          channelId: this.$http.channelId,
          token: this.token,
          floorState: 1,
        })
        .then((res) => {
          if (res.code == 10000) {
            console.log('floor<<<<<<>>>',res.data)
            this.floorList = res.data;
            // const floorData = []
            // this.floorList.map(t => {
            //   if(t.moduleCode == 1) floorData.push(t)
            // })
            // this.$refs.information.set(floorData)
          }
        });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    jumpUrl(url) {
      if (url.indexOf('http') > -1) {
        window.open(url)
      } else {
        window.open(`https://${url}`)
      }
    }
  },
};
</script>
<style scoped lang="scss">
.pt10 {
  padding-top: 10px;
}

.carousel {
  position: relative;
  // background: url(".././assets/images/header/default.jpg") 50% no-repeat;
  width: 100%;
  height: 357px;
  max-height: 357px;
  overflow: hidden;
  background-size: 100% 100%;

  .gonggao {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.footerlink {
  display: flex;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto 30px auto;
  justify-content: space-around;
  overflow-x: auto;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  img {
    width: 340px;
    border-radius: 20px;
    margin: 0 5px;
    cursor: pointer;
  }
}

.footerBg {
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .carousel {
    height: 47.6vw;
  }
}
</style>
